<template>
  <a href="https://skinstudio.klikbook.dk/" target="_blank" rel="noopener"
    ><slot>Book en tid</slot></a
  >
</template>

<script>
export default {
  name: "LinkBooking",
};
</script>
