import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import "./assets/css/tailwind.css"

//axios.defaults.baseURL = process.env.VUE_APP_API_URL

const app = createApp(App).use(store).use(router);
app.config.globalProperties.$axios = axios;
app.mount('#app');