<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 32.69">
    <path
      fill="currentColor"
      d="M135.09,437.15c-6.64,0-11.5-3.17-12.59-8.58l4.58-1.08c.72,3.72,3.94,5.86,8.17,5.86,3.4,0,6.66-1.4,6.6-5.23s-4.15-4.82-8.49-6c-4.93-1.35-9.86-2.9-9.86-8.76s4.88-8.89,10.67-8.89c5.6,0,10.65,2.36,11.84,7.83l-4.35,1.11c-.78-3.54-3.73-5.11-7.29-5.11-3.2,0-6.37,1.39-6.37,5,0,3.28,3.56,4.25,7.59,5.33,5.06,1.38,10.87,2.95,10.87,9.35s-5.4,9.16-11.37,9.16Zm43.79-.43L167.71,422.2H165v14.52h-4.48V404.89H165v13.3h2.71l10.85-13.3H184l-12.47,15.17,13.08,16.66Zm18.37,0V404.89h4.48v31.83Zm42.3-31.83H244v31.83h-4.84l-16.64-25.26v25.26h-4.44V404.89h4.8L239.55,430V404.89ZM289,437.15c-6.64,0-11.51-3.17-12.59-8.58l4.57-1.08c.72,3.72,3.94,5.86,8.18,5.86,3.4,0,6.66-1.4,6.59-5.23s-4.14-4.82-8.48-6c-4.94-1.35-9.87-2.9-9.87-8.76s4.89-8.89,10.68-8.89c5.6,0,10.65,2.36,11.84,7.83l-4.35,1.11c-.78-3.54-3.73-5.11-7.29-5.11-3.2,0-6.37,1.39-6.37,5,0,3.28,3.55,4.25,7.58,5.33,5.07,1.38,10.88,2.95,10.88,9.35s-5.4,9.16-11.37,9.16Zm42.35-32.26v4h-8.06v27.8h-4.44v-27.8h-8.08v-4Zm23.77,32.26c-6.6,0-11.71-3.89-11.71-11.08V404.89h4.48v20.55c0,5.25,3.15,7.66,7.23,7.66s7.29-2.43,7.29-7.66V404.89h4.48v21.18C366.85,433.26,361.72,437.15,355.08,437.15Zm27.46-.43V404.89H393c9.8,0,14.78,6.57,14.78,15.89s-5,15.94-14.78,15.94Zm4.48-4.05h5.83c7,0,10.27-4.61,10.27-11.89s-3.31-11.86-10.27-11.86H387v23.75Zm35,4.05V404.89h4.48v31.83Zm34.56.43c-9,0-15.92-6.21-15.92-16.37s6.89-16.32,15.92-16.32,15.89,6.19,15.89,16.32S465.59,437.15,456.61,437.15Zm0-4.21c6.37,0,11.41-4.41,11.41-12.16s-5-12.09-11.41-12.09-11.46,4.41-11.46,12.09S450.21,432.94,456.61,432.94Z"
      transform="translate(-122.5 -404.46)"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Logo",
};
</script>
