<template>
  <a
    href="https://www.google.com/maps/dir//Skin+Studio,+Hulg%C3%A5rdsvej+3,+2400+K%C3%B8benhavn/@55.6950312,12.5079409,17z"
    rel="noopener"
    target="_blank"
    ><slot>Find vej</slot></a
  >
</template>

<script>
export default {
  name: "LinkDirections",
};
</script>
