<template>
  <footer
    id="footer"
    class="bg-primary text-gray-50 py-24 md:py-40 px-10 xl:px-16 text-sm flex gap-8 sm:gap-4 flex-col sm:flex-row flex-wrap md:flex-nowrap justify-center sm:items-start sm:justify-between leading-6 text-center sm:text-left"
  >
    <div class="w-full md:w-min">
      <logo class="h-3 sm:h-4 mx-auto sm:mb-8 md:m-0" />
    </div>
    <div>
      <p>
        Hulgårdsvej 3, st. tv.<br />
        2400 København NV
      </p>
      <p>
        <a href="tel:+4553797953">+45 53 79 79 53</a><br />
        <a href="mailto:book@skinstudio.dk">book@skinstudio.dk</a>
      </p>
    </div>
    <ul>
      <li>
        <router-link to="/haarfjerning-med-laser" class="block md:inline-block"
          >Laser hårfjerning</router-link
        >
      </li>
      <li>
        <router-link to="/faq" class="block md:inline-block">F.A.Q</router-link>
      </li>
      <li>
        <router-link to="/behandlinger" class="block md:inline-block"
          >Priser</router-link
        >
      </li>
      <li>
        <router-link to="/om-skinstudio" class="block md:inline-block"
          >Om Skinstudio</router-link
        >
      </li>
    </ul>
    <ul>
      <li>
        <link-directions />
      </li>
      <li>
        <link-booking />
      </li>
      <li>
        <span class="cursor-pointer" @click="$store.commit('toggleOpenHours')"
          >Åbningstider</span
        >
      </li>
    </ul>
    <div class="flex justify-center items-center gap-8">
      <a
        href="https://facebook.com/skinstudiodk"
        target="_blank"
        aria-label="Skinstudio Facebook page"
        rel="noopener"
      >
        <facebook class="h-5" />
      </a>
      <a
        href="https://instagram.com/skinstudiodk"
        target="_blank"
        aria-label="Skinstudio Instagram profile"
        rel="noopener"
      >
        <instagram class="h-6" />
      </a>
    </div>
  </footer>
</template>

<script>
import Logo from "./Logo.vue";
import Facebook from "../assets/icons/Facebook.vue";
import Instagram from "../assets/icons/Instagram.vue";
import LinkDirections from "./LinkDirections.vue";
import LinkBooking from "./LinkBooking.vue";

export default {
  name: "Footer",
  components: {
    Logo,
    Facebook,
    Instagram,
    LinkDirections,
    LinkBooking,
  },
};
</script>
