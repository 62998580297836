<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="w-4" viewBox="0 0 160 160">
    <rect class="vertical-line" x="70" :width="size" height="160" />
    <rect class="horizontal-line" y="70" width="160" :height="size" />
  </svg>
</template>

<script>
export default {
  name: "IconPlus",
  props: {
    size: {
      default: 20,
    },
  },
};
</script>
