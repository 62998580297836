import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({

	/**
	 * States
	 */
	state: {
		ui: {
			nav: false,
			navbar: false,
			navbarHeight: undefined,
			openHours: false
		},
		data: {
			google: {
				rating: 0,
				reviews: []
			},
			instagram: [],
		}
	},

	/**
	 * Getters
	 */
	getters: {
		getData: state => {
			return state.data
		}
	},

	/**
	 * Mutations
	 */
	mutations: {

		// Set data
		setData(state, [key, payload]) {
			state.data[key] = payload
		},

		// Toggle Open Hours Modal
		toggleOpenHours(state) {

			// Always close Nav when opening the OpenHours 
			if (state.ui.nav) {
				this.commit('toggleNavState');
			}
			state.ui.openHours = !state.ui.openHours
		},

		// Toggle Nav state
		toggleNavState(state, payload) {
			if (state.ui.openHours) {
				this.commit('toggleOpenHours');
			}
			state.ui.nav = payload === undefined ? !state.ui.nav : payload
		},

		// Toggle Navbar state
		toggleNavbarState(state, payload) {
			state.ui.navbar = payload
		},
	},

	/**
	 * Actions
	 */
	actions: {
		fetchData(context) {
			axios.get('/api/items/staff').then(response => {
				context.commit('setData', ['staff', response.data.data])
			})
			axios.get('/api/items/faq').then(response => {
				context.commit('setData', ['faq', response.data.data])
			})
			axios.get('/api/items/services').then(response => {
				context.commit('setData', ['service', response.data.data])
			})
			axios.get('/api/google').then(response => {
				context.commit('setData', ['google', response.data.result])
			})
			axios.get('/api/instagram').then(response => {
				context.commit('setData', ['instagram', response.data.data.filter((item) => {
					return item.media_type == 'IMAGE'
				})])
			})
		}
	},
})
