<template>
  <header
    id="header"
    class="bg-primary text-white py-6 px-10 xl:px-16 w-full z-20 top-0 left-0 flex items-center justify-between"
    :class="$store.state.ui.navbar ? 'fixed animate-slide-in-down' : 'absolute'"
  >
    <div class="flex-1 flex justify-between items-center">
      <router-link to="/" alt="Skinstudio Logo" aria-label="Skinstudio Logo"
        ><logo class="h-3 sm:h-4"
      /></router-link>
      <link-booking class="text-lg hidden sm:block">Book en tid</link-booking>
    </div>
    <svg width="1" height="48" class="mx-4 sm:mx-16 text-gray-400 opacity-70">
      <line x1="0" y1="2" x2="0" y2="46" style="stroke: currentColor"></line>
    </svg>
    <div class="flex lg:w-1/6 items-center justify-between">
      <ul class="hidden sm:flex gap-8 items-center">
        <a
          href="https://facebook.com/skinstudiodk"
          target="_blank"
          rel="noopener"
        >
          <facebook class="h-5" />
        </a>
        <a
          href="https://instagram.com/skinstudiodk"
          target="_blank"
          rel="noopener"
        >
          <instagram class="h-6" />
        </a>
      </ul>
      <nav-button
        :class="!$store.state.ui.nav ? 'opacity-100 delay-500' : 'opacity-0'"
      />
    </div>
  </header>
</template>

<script>
import Logo from "./Logo.vue";
import NavButton from "./NavButton.vue";
import Facebook from "../assets/icons/Facebook.vue";
import Instagram from "../assets/icons/Instagram.vue";
import LinkBooking from "./LinkBooking.vue";

export default {
  /**
   * * Name
   */
  name: "Navbar",

  /**
   * * Components
   */

  components: {
    Logo,
    NavButton,
    Facebook,
    Instagram,
    LinkBooking,
  },

  data() {
    return {
      isTop: true,
    };
  },

  beforeMount() {
    // Scroll spy
    window.onscroll = () => {
      if (window.scrollY > 60) {
        this.$store.commit("toggleNavbarState", true);
      } else {
        this.$store.commit("toggleNavbarState", false);
      }
    };
  },
};
</script>
