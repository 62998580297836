<template>
  <app-navbar />
  <navigation />
  <router-view v-slot="{ Component }">
    <transition
      enter-from-class="opacity-0"
      enter-active-class="transition-all transition-slow duration-500"
      leave-to-class="opacity-0"
      leave-active-class="transition-all transition-slow duration-500"
      mode="out-in"
      name="fade"
    >
      <component :is="Component" class="px-10 xl:px-16 py-10 xl:py-16" />
    </transition>
  </router-view>

  <transition
    enter-from-class="opacity-0"
    enter-active-class="transition-all transition-slow duration-500"
    leave-to-class="opacity-0"
    leave-active-class="transition-all transition-slow duration-500"
    mode="out-in"
    name="fade"
  >
    <opening-hours-modal />
  </transition>
  <app-footer />
</template>

<script>
import AppFooter from "./components/AppFooter.vue";
import AppNavbar from "./components/AppNavbar.vue";
import Navigation from "./components/Navigation.vue";
import OpeningHoursModal from "./components/OpeningHoursModal.vue";

export default {
  /**
   * * Name
   */
  name: "App",

  /**
   * * Components
   */
  components: {
    AppFooter,
    AppNavbar,
    Navigation,
    OpeningHoursModal,
  },

  /**
   * Data
   */
  beforeCreate() {
    this.$store.dispatch("fetchData");
  },
};
</script>
