<template>
  <div
    v-show="$store.state.ui.openHours"
    class="fixed w-full h-full left-0 top-0 flex items-center justify-center p-10 bg-black bg-opacity-75 z-10"
    @click="$store.commit('toggleOpenHours')"
  >
    <div class="bg-white shadow p-10 max-w-lg flex-1 relative" @click.stop>
      <plus
        title="Luk"
        alt="Luk"
        @click="$store.commit('toggleOpenHours')"
        size="12"
        class="cursor-pointer ml-auto w-6 transform rotate-45 relative -right-6 -top-6"
      />
      <heading-2>Åbningstider</heading-2>
      <table class="w-full uppercase">
        <tbody>
          <tr
            v-for="(item, index) in openingHours"
            :key="index"
            :class="index == today ? 'font-semibold text-black' : ''"
          >
            <td>{{ item.label }}</td>
            <td class="text-right">{{ item.open }} - {{ item.close }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Heading2 from "./Heading2.vue";
import Plus from "../assets/icons/Plus.vue";

export default {
  name: "OpeningHoursModal",

  components: {
    Heading2,
    Plus,
  },

  computed: {
    today: () => {
      const d = new Date();
      return d.getDay() == 0 ? 6 : d.getDay() - 1;
    },
  },

  data() {
    return {
      openingHours: [
        { label: "Mandag", open: "10.00", close: "22.00" },
        { label: "Tirsdag", open: "10.00", close: "22.00" },
        { label: "Onsdag", open: "10.00", close: "22.00" },
        { label: "Torsdag", open: "10.00", close: "22.00" },
        { label: "Fredag", open: "10.00", close: "16.00" },
        { label: "Lørdag", open: "08.00", close: "16.00" },
        { label: "Søndag", open: "08.00", close: "16.00" },
      ],
    };
  },
};
</script>

<style></style>
