<template>
  <main class="home">
    <section class="relative flex overflow-hidden -m-10 lg:m-0">
      <div
        class="bg-center bg-cover absolute w-full h-full left-0 top-0"
        style="
          background-image: url(/assets/e2ddea4c-5136-436d-b2d2-e993dd1f2fdc.jpg?format=webp);
        "
      />
      <div
        class="relative bg-black bg-opacity-40 md:bg-opacity-30 flex flex-col w-full justify-center py-32 sm:py-40 lg:py-64 2xl:py-120 px-10 md:px-20 2xl:px-44"
      >
        <h1 class="text-xl md:text-3xl font-semibold text-white">
          Velkommen til Skin Studio
        </h1>
        <hr class="w-16 my-8 border-2" />
        <h2 class="text-3xl md:text-7xl font-semibold text-white">
          Professionel laser <br />hårfjerning i København
        </h2>
      </div>
    </section>
    <section class="py-24 lg:py-48">
      <div class="container">
        <div class="flex justify-between">
          <div class="w-full md:w-6/12 pt-8">
            <h2 class="uppercase font-semibold mb-4 text-gray-500 text-lg">
              Om SkinStudio
            </h2>
            <heading-2>Certificerede medarbejdere</heading-2>
            <p>
              Skin Studio er godkendt hos styrelsen for patientsikkerhed. Dette
              er din garanti for en professionel behandling, samt at du er i
              trygge hænder, hos uddannet personale.
            </p>
            <p>
              Hos SkinStudio har vi flere års erfaring med permanent hårfjerning
              med laser til både kvinder og mænd. Permanent hårfjerning med
              laser er en effektiv og næsten smertefri måde at fjerne uønsket
              hårvækst over hele kroppen. Vi er alle specialuddannede til at
              udføre hårfjerning med laser.
            </p>
            <router-link
              to="haarfjerning-med-laser"
              class="link inline-block mt-8"
            >
              Læs mere om laser hårfjerning
            </router-link>
          </div>
          <div class="w-5/12 hidden md:block">
            <pic
              url="/assets/b0417d5b-3bfd-48a5-80ad-91cf5036ab88.jpg"
              :screens="[
                { screen: 300, width: 310 },
                { screen: 1024, width: 400 },
                { screen: 1280, width: 430 },
                { screen: 1920, width: 500 },
              ]"
            />
          </div>
        </div>
      </div>
    </section>
    <google-reviews />
    <section class="py-24 lg:py-40">
      <div class="container">
        <heading-2>Følg os på Instagram</heading-2>
        <ul class="grid grid-cols-2 sm:grid-cols-4 gap-8">
          <li
            v-for="media in this.$store.getters.getData.instagram.slice(0, 8)"
            :key="media.id"
          >
            <a
              rel="noopener"
              :href="media.permalink"
              target="_blank"
              :style="`background-image:url(${media.media_url});`"
              class="bg-cover bg-center aspect-w-1 aspect-h-1 block"
              :aria-label="media.caption"
            />
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script>
import GoogleReviews from "../components/GoogleReviews.vue";
import Heading2 from "../components/Heading2.vue";
import Pic from "../components/Pic.vue";
export default {
  /**
   * Name
   */
  name: "Home",

  /**
   * Components
   */
  components: {
    GoogleReviews,
    Heading2,
    Pic,
  },
};
</script>
