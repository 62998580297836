<template>
  <img
    :src="url"
    :alt="alt"
    :srcset="sizes"
    :loading="loading"
    :width="width"
    :height="height"
  />
</template>

<script>
export default {
  name: "Pic",

  /**
   * Data
   */
  data() {
    return {
      width: "",
      height: 0,
    };
  },

  /**
   * Props
   */
  props: {
    alt: {
      required: false,
    },
    url: {
      required: true,
    },
    screens: {
      required: true,
      type: Array,
    },
    loading: {
      default: "lazy",
      type: String,
    },
  },

  /**
   * Computed
   */
  computed: {
    sizes() {
      let rtn = [];

      this.screens.forEach((size) => {
        var url = `${this.url}?format=webp`;

        for (const [key, value] of Object.entries(size)) {
          switch (key) {
            case "fit":
              url += `&fit=${value}`;
              break;
            case "width":
              url += `&width=${value}`;
              break;
            case "height":
              url += `&height=${value}`;
              break;
          }
        }
        if (size.screen) {
          rtn.push(`${url} ${size.screen}w`);
        }
      });

      return rtn.join();
    },
  },

  methods: {
    getClosestWidth: (data, target) =>
      data.reduce((acc, obj) =>
        Math.abs(target - obj.width) < Math.abs(target - acc.width) ? obj : acc
      ),
  },

  mounted() {
    this.width = this.getClosestWidth(this.screens, window.innerWidth).width;
  },
};
</script>
