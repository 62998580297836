<template>
  <nav-button
    class="fixed top-6 right-10 xl:right-16 mt-1 z-50"
    :class="$store.state.ui.nav ? 'opacity-100 ' : 'opacity-0 delay-500 '"
  />
  <nav
    id="navigation"
    class="px-10 sm:px-16 md:px-24 py-24 fixed z-40 right-0 top-0 h-full w-full sm:w-7/12 lg:w-5/12 2xl:w-2/6 transform duration-500 bg-white flex flex-col"
    :class="
      $store.state.ui.nav
        ? 'translate-x-0 shadow-soft'
        : 'translate-x-full shadow-none'
    "
  >
    <div
      class="language-bar text-xs md:text-base flex mb-12 2xl:mb-20 transition-opacity"
      :class="
        $store.state.ui.nav
          ? 'animate-fade-in-up opacity-100 duration-500 delay-200'
          : 'opacity-0 duration-75 delay-75'
      "
    >
      <link-directions class="mr-5" />
      <link-booking class="mr-5">Book en tid</link-booking>
      <span
        @click="$store.commit('toggleOpenHours')"
        class="mr-5 cursor-pointer"
        >Åbningstider</span
      >
    </div>

    <!-- Navigation -->
    <div
      class="menu-item transition-opacity mb-12"
      :class="
        $store.state.ui.nav
          ? 'animate-fade-in-up opacity-100 duration-500 delay-500'
          : 'opacity-0 duration-75 delay-75'
      "
    >
      <ul class="text-3xl 2xl:text-4xl font-semibold text-gray-600">
        <li
          v-for="(item, index) in menuLinks"
          :key="index"
          class="mb-6 2xl:mb-8"
        >
          <router-link :to="item.url" active-class="active text-black">{{
            item.title
          }}</router-link>
        </li>
      </ul>
    </div>

    <div
      class="menu-item transition-opacity mt-auto"
      :class="
        $store.state.ui.nav
          ? 'animate-fade-in-up opacity-100 duration-500 delay-700'
          : 'opacity-0 duration-75 delay-75'
      "
    >
      <div
        v-show="false"
        class="flex text-3xl font-semibold items-center text-black"
      >
        <phone-icon class="w-10 mr-6" />
        <a href="#">+45 53 79 79 53</a>
      </div>
    </div>
  </nav>
  <div
    v-show="$store.state.ui.nav"
    @click="$store.commit('toggleNavState')"
    class="click-capture fixed right-0 top-0 w-screen h-screen z-10"
  />
</template>

<script>
import NavButton from "./NavButton.vue";
import { PhoneIcon } from "@heroicons/vue/outline";
import LinkBooking from "./LinkBooking.vue";
import LinkDirections from "./LinkDirections.vue";

export default {
  name: "Navigation",

  components: {
    NavButton,
    PhoneIcon,
    LinkBooking,
    LinkDirections,
  },

  data() {
    return {
      menuLinks: [
        { title: "Forside", url: "/" },
        { title: "Laser hårfjerning", url: "haarfjerning-med-laser" },
        { title: "Priser", url: "behandlinger" },
        { title: "F.A.Q.", url: "faq" },
        { title: "Om SkinStudio", url: "om-skinstudio" },
        { title: "Kontakt", url: "kontakt" },
      ],
    };
  },
};
</script>
