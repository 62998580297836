<template>
  <section
    class="py-24 lg:py-40 px-10 2xl:px-16 bg-gray-50 -mx-10 lg:-mx-0"
    style="background-size: 11px"
  >
    <div class="container flex justify-between items-start">
      <heading-2>Hvad siger vores kunder?</heading-2>
      <div class="navbtn text-black flex">
        <button
          aria-label="Previous"
          class="nav-prev"
          :class="states.left ? '' : 'opacity-25'"
        >
          <chevron-left-icon class="w-9" />
        </button>
        <button
          aria-label="Next"
          class="nav-nxt"
          :class="states.right ? '' : 'opacity-25'"
        >
          <chevron-right-icon class="w-9" />
        </button>
      </div>
    </div>

    <div class="max-w-hd mx-auto">
      <swiper
        ref="googleSwiper"
        class="-mx-2 p-2"
        :breakpoints="carousel.breakpoints"
        :navigation="carousel.navigation"
        :space-between="32"
        @slideChange="setNavigationState"
      >
        <swiper-slide
          v-for="(review, index) in $store.getters.getData.google.reviews"
          :key="index"
        >
          <a
            class="bg-white p-6 h-full flex flex-col shadow-sm"
            target="_blank"
            :href="review.author_url"
            rel="noopener"
          >
            <div class="flex items-center justify-between">
              <img
                :src="review.profile_photo_url"
                :alt="review.author_name"
                width="56"
                height="56"
                class="w-14"
                loading="lazy"
              />
              <div class="flex-1 ml-4 leading-snug">
                <span class="m-0">{{ review.author_name }}</span>
                <div class="flex">
                  <span
                    v-for="count_index in review.rating"
                    :key="count_index"
                    class="star text-yellow-400 text-lg leading-none"
                    >★</span
                  >
                </div>
              </div>
            </div>
            <div class="text-sm mt-4 mb-auto">
              {{ review.text }}
            </div>
            <div class="flex mt-4 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                  <path
                    fill="#4285F4"
                    d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                  />
                  <path
                    fill="#34A853"
                    d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                  />
                  <path
                    fill="#EA4335"
                    d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                  />
                </g>
              </svg>
              <span class="text-xs ml-2">Via Google</span>
            </div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import SwiperCore, { Navigation } from "swiper";

import Heading2 from "./Heading2.vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/outline";

// install Swiper modules
SwiperCore.use([Navigation]);

export default {
  /**
   * Name
   */
  name: "GoogleReviews",

  /**
   * Data
   */
  data() {
    return {
      states: {
        left: false,
        right: true,
      },
      carousel: {
        navigation: {
          nextEl: ".nav-nxt",
          prevEl: ".nav-prev",
        },
        breakpoints: {
          // when window width is >= 320px
          768: {
            slidesPerView: 2,
          },
          // when window width is >= 480px
          1024: {
            slidesPerView: 3,
          },
          // when window width is >= 640px
          1920: {
            slidesPerView: 4,
          },
        },
      },
    };
  },

  methods: {
    setNavigationState(e) {
      e.isEnd ? (this.states.right = false) : (this.states.right = true);
      e.isBeginning ? (this.states.left = false) : (this.states.left = true);
    },
  },

  /**
   * Components
   */
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    Swiper,
    SwiperSlide,
    Heading2,
  },
};
</script>
<style>
.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-pointer-events {
  touch-action: pan-y;
}
.swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  transition-property: transform;
}
</style>
