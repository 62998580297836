import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/om-skinstudio',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/faq',
		name: 'FAQ',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue')
	},
	{
		path: '/haarfjerning-med-laser',
		name: 'HairRemoval',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "hairremoval" */ '../views/HairRemoval.vue')
	},
	{
		path: '/kontakt',
		name: 'Contact',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
	},
	{
		path: '/behandlinger',
		name: 'Services',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,

})

router.beforeEach(() => {
	window.scrollTo(0, 0)
	store.commit('toggleNavState', false)

})

export default router
